.cookie-popup {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 1em;
  text-align: center;
  z-index: 1000; /* Ensure it is above other elements */
}

.cookie-popup button {
  margin-left: 1em;
  padding: 0.5em 1em;
}

.cookie-popup a {
  color: #ffa;
  text-decoration: underline;
}