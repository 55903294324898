/*----------------------------------------*/
/*  24. BREADCRUMB CSS START
/*----------------------------------------*/

.breadcrumb__area {
    padding: 150px 0;
    margin: 10px 0; 
    padding: 100px 100px;
}

.breadcrumb__title {
    font-size: 60px;
    color: var(--tp-common-white);
    text-transform: capitalize;
}

/* Responsive font-size for title */
@media (max-width: 576px) { /* Replace with your $xs breakpoint */
    .breadcrumb__title {
        font-size: 35px;
    }
}

@media (max-width: 768px) { /* Replace with your $sm breakpoint */
    .breadcrumb__title {
        font-size: 40px;
    }
}

/* Additional title styling */
.breadcrumb__title-2 {
    font-size: 50px;
    line-height: 1.1;
    margin-top: 12px;
}

/* Responsive adjustments for title-2 */
@media (max-width: 576px) { /* Replace with your $xs breakpoint */
    .breadcrumb__title-2 {
        font-size: 26px;
    }
}

@media (max-width: 768px) { /* Replace with your $sm breakpoint */
    .breadcrumb__title-2 {
        font-size: 40px;
    }
}

/* Pre-title styling */
.breadcrumb__title-pre {
    display: inline-block;
    height: 24px;
    line-height: 26px;
    font-size: 14px;
    color: #ffffff;
    font-weight: 500;
    background: var(--tp-theme-2);
    border-radius: 4px;
    padding: 0 7px;
    margin-bottom: 12px;
}

/* Breadcrumb list styling */
.breadcrumb__list span {
    letter-spacing: .18em;
    font-family: var(--tp-ff-heading);
    font-size: 12px;
    color: var(--tp-common-white);
    font-weight: 500;
    padding-right: 3px;
    margin-right: 3px;
    text-transform: uppercase;
}

/* Hover effect for links inside span */
.breadcrumb__list span a:hover {
    color: var(--tp-theme-1);
}

/* Additional breadcrumb list styling */
.breadcrumb__list-2 span {
    font-size: 14px;
    color: var(--tp-text-11);
    font-weight: 500;
    padding-right: 3px;
    margin-right: 3px;
    text-transform: capitalize;
}

.breadcrumb__list-2 span a:hover {
    color: var(--tp-theme-1);
}

/* Breadcrumb overlay */
.breadcrumb__overlay {
    position: relative;
}

.breadcrumb__overlay::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(23, 23, 23, .25);
}
