// Background color mixin
@mixin bg-color($color, $opacity) {
    background: rgba($color, $opacity);
}

// tp-afbf mixin
@mixin tp-afbf(
    $style: 'default', 
    $content: '', 
    $top: 50%, 
    $left: 0, 
    $right: auto, 
    $x: 0%, 
    $y: -50%, 
    $height: 12px, 
    $width: 1px, 
    $bg-color: var(--tp-common-black)
) {
    @if ($style == 'center') {
        $top: 50%;
        $left: 50%;
        $x: -50%;
        $y: -50%;
    } @else if ($style == 'full') {
        $top: 0%;
        $left: 0%;
        $x: 0%;
        $y: 0%;
        $width: 100%;
        $height: 100%;
    }

    position: absolute;
    content: $content;
    top: $top;
    left: $left;
    right: $right;
    width: $width;
    height: $height;
    @include transform(translate($x, $y));
    background-color: $bg-color;
}

// Placeholder input mixin
@mixin tp-placeholder {
    &::placeholder {
        @content;
    }
}

// Animate mixin
@mixin animate($animation, $duration, $repeat, $easing) {
    animation: $animation $duration $repeat $easing;
}

// Filter mixin
@mixin filter($value) {
    filter: $value;
}

// Appearance mixin for select
@mixin appearance($value) {
    appearance: $value;
}

// Keyframes mixin
@mixin keyframes($name) {
    @keyframes #{$name} {
        @content;
    }
}

// Background mixin
@mixin background {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

// Transition mixin
@mixin transition($time) {
    transition: all $time ease-out 0s;
}

// Transform mixin
@mixin transform($transforms) {
    transform: $transforms;
}

// Border-radius mixin
@mixin border-radius($man) {
    border-radius: $man;
}

// Sentence case mixin
@mixin sentence-case() {
    text-transform: lowercase;
    &:first-letter {
        text-transform: uppercase;
    }
}

// Flexbox display mixin
@mixin flexbox() {
    display: flex;
}

// Box-shadow mixin
@mixin box-shadow($shadow) {
    box-shadow: $shadow;
}
