
@-webkit-keyframes pulse {
	0% {
	  -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
	}
	70% {
		-webkit-box-shadow: 0 0 0 45px rgba(255,255,255, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	}
}
@keyframes pulse {
0% {
	-moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
	box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
}
70% {
	-moz-box-shadow: 0 0 0 45px rgba(255,255,255, 0);
	box-shadow: 0 0 0 45px rgba(255,255,255, 0);
}
100% {
	-moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	}
}

/* Initial state: hidden and slightly translated */
.fade-in {
	opacity: 0;
	transform: translateY(20px); /* Adjust to your preference */
	transition: opacity 1s ease-out, transform 1s ease-out;
  }
  
  /* Visible state: fully visible and in the correct position */
  .fade-in.show {
	opacity: 1;
	transform: translateY(0);
  }
  