@use '../utils' as *;

/*----------------------------------------*/
/*  16. CONTACT CSS START
/*----------------------------------------*/
.tp-contact-area {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(23, 23, 23, .8);
    }
}

.tp-contact-form-title {
    font-size: 24px;
    margin-bottom: 40px;

    & i {
        margin-right: 8px;
    }
}

.tp-section-wrapper {
    z-index: 9;
    position: relative;
}

.tp-contact-info {
    @media (max-width: $xs) {
        padding: 50px 0;
        padding-right: 0;
    }
}

.tp-contact-form {
    display: block;
    border-bottom: 5px solid var(--tp-common-black);
    z-index: 9;
    position: relative;
    top: 0; // Adjusted for consistency

    @media (max-width: $md) {
        padding: 0 30px;
        margin-bottom: 40px;
    }
    
    @media (max-width: $xs) {
        padding: 0 15px;
        margin-bottom: 40px;
    }

    & input, & textarea {
        width: 100%;
        margin-bottom: 20px;
        padding-left: 20px;
        padding-right: 30px;
        background-color: var(--tp-grey-3);
        border: 0;

        @include tp-placeholder {
            color: var(--tp-text-body);
        }

        a:focus {
            outline: none; /* or other appropriate styling */
        }
    }

    & input {
        height: 60px;
    }

    & textarea {
        height: 215px;
        resize: none;
    }
}

.tp-contact-form-2 {
    position: static;
}

.tp-ct-info__title {
    font-size: 24px;

    & i {
        margin-right: 15px;
    }
}

.p-color-change {
    & p {
        color: #C8C8C8;
    }
}

.ct-info {
    &__box {
        border: 1px solid #F1F1F1;

        & span {
            color: var(--tp-common-black);
        }
    }
}

.tp-ct-form {
    border: 1px solid #F1F1F1;

    @media (max-width: $xs) {
        padding: 0 15px;
    }

    & input, & textarea {
        background-color: var(--tp-grey-3);
        border: 0;

        @include tp-placeholder {
            color: var(--tp-text-body);
        }
    }

    & input {
        width: 100%;
        float: none; // Changed from float-left for better alignment
        margin-right: 0;
        height: 60px;
    }

    & textarea {
        height: 209px;
        width: 100%;
    }
}

.tp-ct-map {
    height: 787px;
    margin-top: -140px;
    position: relative;
    z-index: -1;

    @media (max-width: $xs) {
        height: 480px;
    }

    & iframe {
        width: 100%;
        height: 100%;
    }
}
