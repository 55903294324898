#loading {
    background-color: #fff;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 999999;
    margin-top: 0;
    top: 0;
    display: none; // Keep this if it's part of your loading logic
}

#loading-center {
    width: 100%;
    height: 100%;
    position: relative;
}

#loading-center-absolute {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

// Responsive adjustments for loading center
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    #loading-center-absolute {
        width: 40%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    #loading-center-absolute {
        width: 40%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    #loading-center-absolute {
        width: 45%;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    #loading-center-absolute {
        width: 50%;
    }
}

@media (max-width: 575px) {
    #loading-center-absolute {
        width: 60%;
    }
}

.object {
    width: 20px;
    height: 20px;
    background-color: #5f3afc;
    border-radius: 50%;
    margin-right: 20px;
    margin-bottom: 20px;
    position: absolute;	
}

#object_one {
    animation: object 2s linear infinite;
}

#object_two { 
    animation: object 2s linear infinite -.4s;
}

#object_three { 
    animation: object 2s linear infinite -.8s; 
}

#object_four { 
    animation: object 2s linear infinite -1.2s; 
}

#object_five { 
    animation: object 2s linear infinite -1.6s; 
}

@keyframes object {
    0% { left: 100px; top: 0; }
    80% { left: 0; top: 0; }
    85% { left: 0; top: -20px; width: 20px; height: 20px; }
    90% { width: 40px; height: 15px; }
    95% { left: 100px; top: -20px; width: 20px; height: 20px; }
    100% { left: 100px; top: 0; }		
}

.loading-icon .loading-logo {
    width: 64px;
    height: 64px;
    animation: ghurche 4s linear infinite -1.2s; 
}

@media (max-width: 575px) {
    .loading-icon .loading-logo {
        margin-bottom: 10px;
    }
}

@keyframes ghurche {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
